import React, { useEffect, useState } from 'react';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import BreadCrumb from '../BreadCrumb';
import { withTranslation } from 'react-i18next';
import { getViewFromLocal, storeViewToLocal, viewCreateRequest, viewDelete, viewListGetRequest, viewsMetadataGetRequest, viewUpdateRequest } from 'store/views/action';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { ToastContainer } from 'react-toastify';
import AddView from './innerComponent/AddView';
import DeleteViewModal from './innerComponent/DeleteViewModal';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/ListingLoader';
import { isJson } from 'utils';
import ImportModal from './innerComponent/ImportDataModal';
import ViewAll from './innerComponent/ViewAllView';
import UpdateView from './innerComponent/UpdateView';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import SimpleBar from 'simplebar-react';
import { isEqual } from 'lodash';

const ViewTab: React.FC<any> = (props: any) => {
    const navigate = useNavigate();
    
    const { metadata, model, title, isBulkUpload, downloadBulkTemplate, child, isSubAgent } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const viewslist: any = useSelector((state: ApplicationState) => state.views.list);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const storeView = useSelector((state: ApplicationState) => state.views.storeView);
    const viewsFields = useSelector((state: ApplicationState) => state.views.metaDataFields);
    const viewsMetaLoading = useSelector((state: ApplicationState) => state.views.metaLoading);

    // State Variables
    const [activeTab, setActiveTab] = useState<any>('zilter_default');
    const [views, setViews] = useState<any>([]);
    const [allViews, setAllViews] = useState<any>([]);
    const [isAddviewopen, setAddviewOpen] = useState<any>(false);
    const [isCreateModal, setCreateOpen] = useState<boolean>(false);
    const [viewname, setViewname] = useState<string>('');
    const [defaultFields, setFields] = useState<any>([]);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [record, setRecord] = useState<any>([]);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [viewId, setviewId] = useState<string>('zilter_default');
    const [create, setCreate] = useState<boolean>(false);
    const [isImportOpen, setIsImportOpen] = useState<boolean>(false);
    const [importAccess, setImportAccess] = useState<any>(null);
    const [viewAllOpwn, setViewAllOpen] = useState<boolean>(false);
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [viewlist, setViewList] = useState<any>([]);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [list, setList] = useState<any>([]);

 
     const execute = () => {
            let a = isEqual(viewslist, list)
            return !a
        }
    
        // Update list data
        useEffect(() => {
            const parsedData: any = viewslist && viewslist?.length ? viewslist.map((item: any) => {
                let valuesJson = item?.valuesJson || item;
                let values = { ...item, ...valuesJson }
                return values
            }) : []
            let data = parsedData && parsedData.length ? parsedData : []
            setViewList(data)
            setList(viewslist)
        }, [execute()])


    useEffect(() => {
        if(storeView?.[model]) {
            setviewId(storeView?.[model])
            setActiveTab(storeView?.[model])
        }
        else {
            setviewId('zilter_default')
            setActiveTab('zilter_default')
        }
    }, [storeView?.[model]])

    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            dispatch(storeViewToLocal({[model]: tab}))
        }
    };

    useEffect(() => {
        dispatch(getViewFromLocal());
        let filterObject = {pageSize:500}
        dispatch(viewsMetadataGetRequest(filterObject))
    }, [])

    const handlePrivateViews = (item: any) => {
        if(item?.isprivate === "true" || item?.isprivate === true) {
            let user = item?.createdBy?.id || item?.createdBy
            if(user === userProfile?.id) return true
            else return false
        }
        else return true
    }

    useEffect(() => {
        if (viewlist && viewlist?.length) {           
            let newList = viewlist?.filter((item: any) => (handlePrivateViews(item)))
            setViews(newList)
        }
        else {
            setViews([])
        }
    }, [viewlist])

    useEffect(() => {
            const controller = new AbortController();
            const signal = controller.signal;
    
            // Clear timer and cancel request on unmount
            const cleanup = () => {
                controller.abort("New request");
                clearTimeout(timer);
            };

            let parent: any = []
            parent = [{
                "key": "location",
                "keyLabel": 'Location',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": ["listing"],
                "valuesLabel": [],
                "property": [],
                "quick": true,
            }
        ]
    
            // Main logic wrapped in a timer
            const timer = setTimeout(() => {
                const filtersData: any = {
                    filters: [{
                    "quick": [],
                    "advance": [],
                    "search": {
                        "term": model,
                        "field": "model"
                    }
                  }],
                    sorts: [
                        {
                            "field": 'createdAt',
                            "order": 'desc'
                        }
                    ],
                    
                }
                if (parent?.length > 0) {
                    filtersData.filters[0].parent = parent
                }
                dispatch(viewListGetRequest(filtersData, 0, 500, signal));
            }, 600);
            // Cleanup on unmount or effect rerun
            return cleanup;
        }, [triggerApi])
    

    const onCreate = (formValues: any) => {
        let data = {...formValues, 
            "primary": "true",
            "model": model,
            "location": "listing",
            "fields": JSON.stringify(defaultFields),
            "filters": JSON.stringify([]),
        }
        const handleSuccess = (body: any) => {
            setViewname("")            
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            setAddviewOpen(false)
            toggleTab(`${body?.id}`)
        }
        const handleFailure = () => {

        }
        dispatch(viewCreateRequest(data, handleSuccess, handleFailure))
    }

    const onUpdate = (formValue: any) => {
        const handleSuccess = () => {
            setViewname("")
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            setIsEditOpen(false)
        }
        const handleFailure = () => {

        }
        dispatch(viewUpdateRequest(record.id, formValue, handleSuccess, handleFailure))
    }

    useEffect(() => {
        let defaultFields: any = [];
        metadata && metadata.length &&
            metadata.map((field: any) => {
                let item = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson) : {}
                defaultFields.push({ id: field.id, extras: { key: item.key, show: item.tablecolumn } })
            })
        setFields(defaultFields)
    }, [metadata])

    const onDelete = (id: any) => {
        const handleSuccess = () => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            setisDeleteOpen(false);            
            setviewId('zilter_default')
            setActiveTab('zilter_default')
        }
        const handleFailure = () => {

        }
        dispatch(viewDelete(id, handleSuccess, handleFailure));
    }

    useEffect(() => {
        setviewId(activeTab)
    }, [activeTab])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
            setImportAccess(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false) : setViewAccess(true)
                        }
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
            let importaccess = subLevelPermissionsList?.import;
            if (importaccess && importaccess.length) {
                importaccess.map((item: any, index: any) => {
                    if (item.name.toLowerCase() == 'import') {
                        item.value == "" || item.value == "none" ? setImportAccess(null) : setImportAccess(item.value)
                    }

                })
            }
        }
    }, [subLevelPermissionsList, userProfile])
    

    return (
        <div>
            <ToastContainer closeButton={true} />
            {
                !authLoading ?
                    viewAccess ?
                        <React.Fragment>
                            <AddView
                                onCreate={onCreate}
                                viewname={viewname}
                                setViewname={setViewname}
                                props={props}
                                show={isAddviewopen}
                                onCloseClick={() => setAddviewOpen(false)}
                                setTriggerApi={setTriggerApi}
                            />
                             {isAddviewopen && <AddView
                                show={isAddviewopen}
                                onCloseClick={() => setAddviewOpen(false)}
                                dataFields={viewsFields}
                                props={props}
                                onCreate={onCreate}
                            />}
                            <UpdateView
                                onUpdate={onUpdate}
                                props={props}
                                data={record}
                                show={isEditOpen}
                                onCloseClick={() => setIsEditOpen(false)}                                
                                dataFields={viewsFields}
                            />
                             <ViewAll
                                props={props}
                                show={viewAllOpwn}
                                onCloseClick={() => setViewAllOpen(false)}
                                viewlist={views}
                                setisDeleteOpen={setisDeleteOpen}
                                setIsEditOpen={setIsEditOpen}
                                setRecord={setRecord}
                                setViewname={setViewname}

                            />
                            <DeleteViewModal
                                show={isDeleteOpen}
                                onCloseClick={() => setisDeleteOpen(false)}
                                props={props}
                                record={record}
                                onDelete={onDelete}
                            />
                            {isImportOpen && <ImportModal
                                props={props}
                                show={isImportOpen}
                                onCloseClick={() => setIsImportOpen(false)}
                                model={model}
                                downloadBulkTemplate={downloadBulkTemplate}
                            />}
                            {child ? null :
                                <Row className='hstack jsutify-content-between'>
                                    <Col>
                                        <BreadCrumb title={title} pageTitle={title} icon={"ri-list-check"} />
                                    </Col>
                                    <Col className='hstack justify-content-end gap-2'>
                                        {
                                            create ?
                                                <div className='hstack gap-2'>
                                                    {isBulkUpload && importAccess ? <Button
                                                        onClick={() =>  navigate('/import')}
                                                        color="primary"
                                                        size='sm'
                                                        className="btn-label">
                                                        <i className="ri-file-upload-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("student.bulk_entry")}
                                                    </Button> : null}
                                                    {model != 'applications' ? <Button
                                                        onClick={() => setCreateOpen(true)}
                                                        color="primary"
                                                        size='sm'
                                                        className="btn-label">
                                                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("views.create_entry")}
                                                    </Button> : null}
                                                </div>
                                                :
                                                <div className='hstack gap-2'>
                                                    {isBulkUpload ? <Button
                                                        disabled
                                                        color="primary"
                                                        size='sm'
                                                        className="btn-label">
                                                        <i className="ri-file-upload-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("student.bulk_entry")}
                                                    </Button> : null}
                                                    {model != 'applications' ? <Button
                                                        color="primary"
                                                        size='sm'
                                                        disabled
                                                        className="btn-label">
                                                        <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("views.create_entry")}
                                                    </Button> : null}
                                                </div>
                                        }
                                    </Col>
                                </Row>}
                            <Row>
                                <Col xl={12} md={12} sm={12}>
                                    <div className='hstack justify-content-between'>
                                        <div className='hstack justify-content-start'>
                                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                                    <NavItem className={activeTab === 'zilter_default' ? 'hstack justify-content-between border border-bottom-0 border-primary border-end-0' : 'hstack justify-content-between border  border-primary border-end-0'}>
                                                        <NavLink
                                                            className={activeTab === 'zilter_default' ? "fw-medium fs-14 p-1 px-3 py-2" : "fw-medium fs-14 p-1 px-3  py-2"}
                                                            onClick={() => { toggleTab('zilter_default'); }}
                                                            href="#">
                                                            Default
                                                        </NavLink>
                                                    </NavItem>
                                                    {views.slice(0, 5).map((item: any, index: any) => { 
                                                        return <NavItem className={activeTab === `${item.id}` ? `hstack justify-content-between border border-bottom-0  border-primary border-end-0` : `hstack justify-content-between border  border-primary ${views?.length-1 === index ? '': `border-end-0`}`}>
                                                            <NavLink
                                                                className={activeTab === `${item.id}` ? "fw-medium fs-14 p-1 px-3 py-2" : "fw-medium fs-14 p-1 px-3  py-2"}
                                                                onClick={() => { toggleTab(`${item.id}`); }}
                                                                href="#">                                                            
                                                                <div className='text-truncate' style={{ maxWidth: 85, overflow: 'hidden', maxHeight:50 }} title={item.name}>{item.name}</div>
                                                            </NavLink>
                                                            {
                                                                item.name.toLowerCase() == 'zilter_default' ? null :
                                                                    <span onClick={() => { setisDeleteOpen(true); setRecord(item) }} className={activeTab === `${item.id}` ? "cursor-pointer" : "cursor-pointer"}>
                                                                        <i className="ri-close-line label-icon align-middle fs-13 me-2"></i>
                                                                    </span>}
                                                        </NavItem>
                                                    })}
                                                    {views.length > 5 ? (
                                                        <NavItem className="hstack justify-content-between">
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    href="#"
                                                                    className="btn btn-ghost-primary btn-sm dropdown p-0"
                                                                    tag="button"
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        className="btn-icon border border-primary rounded-0"
                                                                        >
                                                                        <i className="ri-more-2-line label-icon align-middle fs-16 me-2"></i>
                                                                    </Button>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <SimpleBar style={{maxHeight: 200}}>
                                                                        {views?.length ? views.map((item: any, index: any) => {
                                                                            if(index < 5) return null
                                                                            else return <NavItem className={activeTab === `${item.id}` ? `hstack justify-content-start bg-primary text-light` : `hstack justify-content-start  border-primary`}>
                                                                                            <NavLink
                                                                                                className={'fw-medium fs-14 p-1 py-2 hstack align-items-center'}
                                                                                                onClick={() => { toggleTab(`${item.id}`); }}
                                                                                                href="#">
                                                                                                <span className={activeTab === `${item.id}` ? 'text-light ms-1' : 'text-primary ms-1'}>
                                                                                                    <div className='text-truncate' style={{ maxWidth: 100, overflow: 'hidden', maxHeight:50 }} title={item.name}>{item.name}</div>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                        }) : null}
                                                                </SimpleBar>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </NavItem>
                                                        ) : null}
                                                </Nav>
                                                <div>
                                                    <Button
                                                        onClick={() => setAddviewOpen(true)}
                                                        color="primary"
                                                        className="btn-label border border-primary rounded-0">
                                                        <i className=" ri-add-line label-icon align-middle fs-16 me-2"></i>
                                                        {props.t("views.add_view")}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div>    
                                                {views?.length ?         
                                                    <NavItem className='hstack justify-content-between' >
                                                        <NavLink
                                                            className='fw-medium fs-14 p-1 px-3 py-2 text-primary'
                                                            onClick={() => setViewAllOpen(true)}
                                                            href="#">
                                                            <span className='text-primary'>{props.t("views.all_views")}</span>
                                                        </NavLink>
                                                    </NavItem> : null}
                                            </div>
                                    </div>
                                </Col>
                                <Col xl={12} md={12} sm={12}>
                                    <TabContent activeTab={activeTab}>
                                        {viewId == 'zilter_default' && <TabPane tabId={'zilter_default'} key={'zilter_default'}>
                                            <props.component {...{
                                                ...props.componentProps,
                                                isCreateModal,
                                                setCreateOpen,
                                                isSubAgent
                                            }}
                                            />
                                        </TabPane>}
                                        {views && views.length ? views.map((item: any, tab: any) => {
                                            if (`${item.id}` == viewId) return <TabPane tabId={`${item.id}`} key={tab}>
                                                <props.component {...{
                                                    ...props.componentProps,
                                                    isCreateModal,
                                                    setCreateOpen,
                                                    viewId,
                                                    isSubAgent
                                                }}
                                                />
                                            </TabPane>
                                        }) : null}
                                    </TabContent>
                                </Col>
                            </Row>
                        </React.Fragment> : <AccessDenied />
                    : <ListingLoader
                        title={title}
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                        thClass="border-bottom-1 table-soft-primary"
                    />
            }
        </div>
    );
};

export default withTranslation()(ViewTab);