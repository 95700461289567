import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Form,
    Button,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";
import { documentListGetRequest } from "store/documents/action";
import axios from "axios";
import config from "../../../config"
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
const { DTS_API_STUDENT, AWS_API } = config.api;

const QuickFormComponent = ({ dataFields, btnAction, btn_text, APAId , data, btndisabled}: any) => {
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const filterFields: any = [];
   
    dataFields && dataFields.length && dataFields.map((field: any) => {           
        let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {...field.valuesJson}   
        if (field.valuesJson) return filterFields.push(item)
    })
    const [formValues, setFormValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);
    const [file, setFile] = useState(null);
    const [fileKey, setFileKey] = useState(null);

    const isFile = (file: any) => {
        return typeof file === 'object' && file !== null && 'size' in file && 'type' in file;
    }

    const SubmitUpload = async (selectedFiles: any, key: any) => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : selectedFiles;
        const studentId: any = data?.studentId;
        const applicationId: any = data?.applicationId ? data?.applicationId : "";
        if (file) {
                const formData = new FormData();
                formData.append('documentType', key);
                if(studentId){
                    formData.append('studentId', studentId);
                }
                if(applicationId){                    
                    formData.append('applicationId', applicationId);
                }
                formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                await axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res: any) => {                    
                    if(res.key) {
                        setFormValues({ ...formValues, [key]: res.key })
                        let filterObject = {studentId:studentId,  applicationId: applicationId}
                        }                   
                })
        }
    }

    const onChange = async (option: any, key: any, field: any) => {  
        if(field.type === "selectboxes" || field.isMulti) {   
            setFormValues({ ...formValues, [key]: option })
        }         
        else if(isFile(option)) {
            const file = option && option.length ? option[0] : option;
            setFile(file)
            setFileKey(key)
            await SubmitUpload(file, key)
            // let name = option.name;
            // let newFormValues = {...formValues, [key]: option?.name}
            // setFormValues(newFormValues) 
            // // feldtoupdate = {[key]:  file}           
        }    
        else if(key === 'parent') {
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else if(key === 'source') {
            let newFormvalues = { ...formValues, [key]: option?.value || option, subagent: null }
            setFormValues(newFormvalues)
        }
        else if(key === 'subagent' && option !=null) {
            setFormValues({ ...formValues, [key]: option?.value || option, source: "c017ce92-15ae-47b1-9ab1-197895ce94d6" })
        }        
        else if(field.type === "datetime" && isNaN(Date.parse(option)) === false) {

            setFormValues({...formValues, [key]: option})
        }
        else if (typeof option === 'object') {            
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            setFormValues({ ...formValues, [key]: option })
        }
    };
    const allMandatoryFieldsFilled = () => {
        if(!filterFields?.length) return false
        for (const field of filterFields) {
            if (field && field.quickCreate && field.validate && field.validate.required && !formValues[field.key]) {
                return false;
            }
        }
        return true;
    };
    useEffect(() => {   
        if(data?.valuesJson) {
            let values = data?.valuesJson && isJson(data?.valuesJson)  ? JSON.parse(data?.valuesJson) : {...data?.valuesJson}
            setFormValues(values)
        }
        else if(data?.id) {
            setFormValues(data)
        }
        else {
            let newData: any = {}
            filterFields && filterFields.length && filterFields.map((item: any, key: any) => {
                const resetvalue = item?.ConditionalDefaultValue?.isDefaultValue ? item?.ConditionalDefaultValue?.values?.value || item?.ConditionalDefaultValue?.values || null : null;
                newData[item.key]= resetvalue;
            })
            setFormValues(newData)
        }
    }, [data])

    const formdisabled = Object.keys(formerror).length === 0;
    return (
        <Form action="" className="d-flex flex-column justify-content-end h-100">
            <div className="live-preview vstack gap-2">
                {filterFields && filterFields.length ? filterFields.map((field: any) => {  
                    let statusoptions = null; 
                    if(field?.process_flows && field.key == 'student_status'){
                        const { statuses } = getAvailableStatuses(field?.process_flows, formValues[field.key], '', true);
                        statusoptions= statuses
                    }
                    if(field.quickCreate) return <FormBuilder
                        placeholder={field.placeholder}
                        name={field.key}
                        onChange={(e: any) => onChange(e, field.key, field)}
                        options={statusoptions || (field.values && field.values.reverse()) || []}
                        label={field.label}
                        type={field.type}
                        value={formValues[field.key]}
                        isEditState={true}
                        actionButtons={false}
                        defaultValue={formValues[field.key]}
                        Editable={true}
                        rest={field}
                        invalid={true}
                        formValues={formValues}
                        dataFields={filterFields}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        resetValue={formValues[field.key]}
                    />
                }): null}
            </div>
            <div className="offcanvas-footer bg-opacity-10 p-3 justify-content-between hstack gap-2">
                <Button
                    className="btn btn-success w-100"
                    disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || btndisabled }
                    onClick={() => btnAction(formValues)}
                >
                    {btn_text}
                </Button>
            </div>
        </Form>
    );
};

export default QuickFormComponent;

