import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, ButtonGroup, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/Preview/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import ArrangeFields from 'Components/Common/FieldsArranger/ArrangeFields';
import QuickFilter from '../Filter/QuickFilter';
import FeatherIcon from "feather-icons-react";
import AddEntryModal from './innerComponent/AddEntryModal';
import UserAvatar from '../UserAvtar/ListingUserAvatar';
import EditModal from './innerComponent/EditColumnModal';
import ImportDataModal from './innerComponent/ImportDataModal';
import { checkTheyOwn, convertCurrency, handleSelectValue, isJson } from 'utils';
import StatusFormModel from '../StatusFormModal';
import CallSelectionModel from '../CallSelectionModel';
import { useCCP } from 'CCPContext/CCPContext';
import { toast } from 'react-toastify';
import { documentDownload } from 'store/documents/action';
import { callCreateRequest, callListGetRequest, pushNotification } from 'store/calls/action';
import { noteCreateRequest, notesMetadataGetRequest } from 'store/notes/action';
import NotesModal from '../Activities/Notes/NotesModal';
import { currencyGetRequest } from 'store/currency/action';
import { viewGetRequest, viewUpdateRequest } from 'store/views/action';

interface ReducerState {
    [key: string]: any;
}

interface FiltersData {
    filters: any[];
    sorts: any[];
}


const Listing = (props: any) => {
    const { viewId, getListAction, deleteAction, detailsGetAction, reducerState, model, createAction, isCreateModal, setCreateOpen, translater, parent, createBtn, updateAction, previewAccordianItems, extraNameBtn, parentId, checkbox, shortListBtn, parentField, applyBtn, initializeData } = props;

    const { initializeCCP } = useCCP();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ['metaDataFields', 'list', 'dataLoading', 'pagination', "metaLoading"]);
    const derivedState: any = useSelector(reducer);
    const { metaDataFields, list, dataLoading, metaLoading } = derivedState;
    let paginationDetails = derivedState.pagination;

    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const viewdetail: any = useSelector((state: ApplicationState) => state.views.details);
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const viewsloading = useSelector((state: ApplicationState) => state.views.dataLoading);
    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [columns, setColumns] = useState<any>([])
    const [view, setView] = useState<any>([])
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [previewModal, setPreview] = useState<boolean>(false);
    const [isImportCSV, setIsImportCSV] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [search, setSearch] = useState<string>("");
    const [record, setRecord] = useState<any>({});
    const [open, setOpen] = useState('1');
    const [arrangeopen, setArrangeopen] = useState(false);
    const [metaData, setMetaData] = useState([]);
    const [ListData, setList] = useState([]);
    const [studentsJson, setstudentsJson] = useState<any>([]);
    const [fields, setFields] = useState<any>(null);
    const [communicate, setCommunicate] = useState<any>(null);
    const [fieldOptions, setFieldOptions] = useState<any>([])
    const [pagesList, setPagesList] = useState<any>([]);
    const [filter, setFilter]: any = useState(null);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [notesModals, setIsNotesOpen] = useState<boolean>(false);
    const [updateRow, setUpdateRow] = useState<any>(null);
    const [statusForm, setStatusForm] = useState<any>(null);
    const [isCallOpen, setIsCallOpen] = useState<boolean>(false);
    const [isCallLoading, setIsCallLoading] = useState<boolean>(false);
    const [url, setUrl] = useState('')
    const [selectedSearchField, setSelectedSearchField]: any = useState({ value: 'all', label: 'All' });
    const [leftFields, setLeftFields] = useState<any>([]);
    const [modal_tooltip_call, setmodal_tooltip_call] = useState<boolean>(false);
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [parentFilter, setParentFilter] = useState<any>([]) 
    const [parentNotesField, setParentNotesField] = useState<any>([]) 
    const [assignRates, setAssignRates] = useState<any>(null)
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [currencySymbol, setCurrencySymbol] = useState<any>(null)
    const [parsedRates, setParsedRates] = useState<any>(null)
    const [viewDetails, setViewDetails] = useState<any>(null)
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    // Handle PageSize Change
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value, page: 0 })
    }


    useEffect(() => {
        let newDetails = {...viewdetail, ...viewdetail?.valuesJson}
        setViewDetails(newDetails)
    }, [viewdetail])

        useEffect(() => {
            dispatch(currencyGetRequest())
        }, [selectedCurrency])
    
        useEffect(() => {
    
            if (updatedCurrency) {
                setSelectedCurrency(updatedCurrency.selectedCurrency);
                setCurrencySymbol(updatedCurrency.selectedCurrencySymbol)
            }
        }, [updatedCurrency]);
    
        useEffect(() => {
            if (conversionRates) {
                setAssignRates(conversionRates)
            }
        }, [conversionRates])
    
        useEffect(() => {
            if (assignRates && assignRates?.length > 0) {
                const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;
    
                // Check if `targetCurrencyJson` exists and is a valid JSON string
                const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                    ? JSON.parse(targetCurrencyJson)
                    : {};
    
                // Set the parsed rates only if convertJson is valid
                setParsedRates(convertJson?.conversion_rates);
            }
        }, [assignRates]);
        
    useEffect(() => {
        setView(viewDetails)
    }, [viewDetails])

    // Set access based on user role and permissions
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCommunicate("all")
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                setAccess(access)
            }
            let communicateaccess = subLevelPermissionsList?.communicate;
            if (communicateaccess && communicateaccess.length) {
                communicateaccess.map((item: any, index: any) => {
                    if (item.name.toLowerCase() == 'communicate') {
                        item.value == "" || item.value == "none" ? setCommunicate(null) : setCommunicate(item.value)
                    }

                })
            }
        }
    }, [subLevelPermissionsList, userProfile])

    // Update metadata fields
    useEffect(() => {
        setMetaData(metaDataFields);
    }, [metaDataFields]);

      useEffect(() => {
            let parent: any = {}
            let parentFilter: any = []
            const notesMetadata: any = [];          
            notesMetadata && notesMetadata.length && notesMetadata.map((field: any) => {           
                let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {...field.valuesJson}   
                if (field.valuesJson) return notesMetadata.push(item)
            })
            notesMetadata && notesMetadata.length && metaData.map((item: any) =>{
                let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
                if(field.key === "student") {
                    parent = {...field, ...item}
                    setParentNotesField({...field, ...item})
                    let filter = {
                        "key": parent?.key,
                        "keyLabel": parent?.label,
                        "condition": "IN",
                        "conditionLabel": "is any of",
                        "values": [studentId],
                        "valuesLabel": [],
                        "property": parent,
                        "quick": true,
                    }
                    setParentFilter([...parentFilter, filter])
                }
                else if(field.key === "application") {
                    parent = {...field, ...item}
                    setParentNotesField({...field, ...item})
                    let filter = {
                        "key": parent?.key,
                        "keyLabel": parent?.label,
                        "condition": "IN",
                        "conditionLabel": "is any of",
                        "values": [applicationId],
                        "valuesLabel": [],
                        "property": parent,
                        "quick": true,
                    }
                    setParentFilter([...parentFilter, filter])
                }
            })
        }, [metaData, record])

     useEffect(() => {    
        if(model === 'students' || model === 'applications') {
            const filters = { pageSize: 500 }
            dispatch(notesMetadataGetRequest(filters));
        }       
    }, []);

    useEffect(() => {
        if (parent && parentId && model != "applications") setUrl(`/${parent}/${parentId}/${model}`)
        else setUrl(`/${model}`)
    }, [])


    // Fetch list data with filters and pagination
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };

        let parent: any = []
        if (parentField && parentField.id && parentId) {
                parent = [{
                    "key": parentField?.key,
                    "keyLabel": parentField?.label,
                    "condition": "IN",
                    "conditionLabel": "is any of",
                    "values": [parentId],
                    "valuesLabel": [],
                    "property": parentField,
                    "quick": true,
                }
            ]
        }
        if(model === 'applications') {
            let filter = [{
                "key": 'application_status',
                "keyLabel": 'Application Status',
                "condition": "NOT_IN",
                "conditionLabel": "is none of",
                "values": ['draft', 'confirmed'],
                "valuesLabel": [],
                "property": null,
                "quick": true,
            }
        ]
            parent = [...parent, ...filter]
        }
        // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData: FiltersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }
            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
            }
            if (parent?.length > 0) {
                filtersData.filters[0].parent = parent
            }
            dispatch(getListAction(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [filter, search, selectedSearchField, pagination?.page, pagination?.size, sortBy, sortOrder, triggerApi, selectedCurrency, parentField])

    // Clear filter selections
    const clearFilter = () => {
        setSearch("")
        setSortBy("createdAt")
        setSortOrder("desc")
        setPagination({
            ...pagination,
            page: 0,
            first: true,
            last: false,
        })
    }

    // Handle search input change
    const onSearchHandle = (e: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(e.target.value)
    }

    // Apply filter changes
    const onFilter = (quickFilter: any, advanceFilter: any) => {
        setPagination({ ...pagination, page: 0 });
        const filter = {
            quick: quickFilter,
            advance: advanceFilter
        }
        setFilter(filter)
    }

    // Handle row click
    const handleLeadClick = (lead: any) => {
        setRecord(lead)
        dispatch(detailsGetAction(lead.id))
        if (lead && lead.id) {
            if (model === "students") {
                setStudentId(lead?.id)
            } else {
                setApplicationId(lead?.id)
                setStudentId(lead?.student?.id)
            }
        }
    };


    // Handle page change
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };

    // Handle delete action
    const onDelete = () => {
        const handleSuccess = (body: any) => {
            initializeData && initializeData([])
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(deleteAction(record.id, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])


    // Check view access permissions
    const handleAccess = (permissionJson: any, fields: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }

    // Check edit access permissions
    const handleEditAccess = (permissionJson: any, fields: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        if (viewId) dispatch(viewGetRequest(viewId))
        else setView(null)
    }, [viewId])

    const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", documentKey); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }
    const handleCallInitiate = (recorddetails: any) => {
        handleLeadClick(recorddetails)
        const hasValidDetails =
            recorddetails?.mobile || 
            recorddetails?.student?.mobile;

        if (hasValidDetails) {
            setIsCallOpen(true);
        } else {
            setmodal_tooltip_call(true)
        }
    };

    // Rendering Cell value
    const renderCell = (cellProps: any, fields: any) => {
        let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id)
        let rendervalue = cellProps.row.original[fields.key];
        let isMergeRender = false;
        const originalFee = parseFloat(cellProps.row?.original?.course_fee);
        const originalCurrency = typeof cellProps.row?.original?.currency === "string" ? cellProps.row?.original?.currency.toUpperCase().match(/[A-Z]+/g)?.[0] : null;
        if (fields?.mergeColumns?.columns) {
            let value = fields?.mergeColumns?.columns
            let values = value.split(",")
            let newValue = "";
            values?.length && values.map((item: any) => {
                newValue = cellProps.row.original[item] ? `${newValue ? newValue : ""} ${cellProps.row.original[item]}${fields.mergeColumns?.separator}` : ""
            })
            rendervalue = cellProps.row.original[fields.key] || newValue;
            isMergeRender = true
        }
        else if (fields.key === "course_fee" && selectedCurrency) {
            if (
                !originalCurrency || 
                isNaN(originalFee) || 
                originalCurrency === selectedCurrency || 
                originalCurrency?.toLowerCase() === "na"
            ) {
                return (
                    <div 
                        style={{ verticalAlign: 'middle' }} 
                        className="align-items-center justify-content-between"
                    >
                        <span>{rendervalue}</span>
                    </div>
                );
            }
                if (parsedRates) {
                    const convertedFee = convertCurrency(originalFee, originalCurrency, selectedCurrency, parsedRates);
                    return (
                        <div 
                            style={{ verticalAlign: 'middle' }} 
                            className="align-items-center justify-content-between"
                        >
                            {currencySymbol} {convertedFee?.toFixed(2)}
                        </div>
                    );
                }          
        }        
        else if (fields.key == "currency") {
            if(selectedCurrency && originalCurrency) {
                return (
                    <div
                        key={cellProps.column.id}
                        style={{ verticalAlign: 'middle' }}
                        className="align-items-center justify-content-between"
                        // {...cellProps.getCellProps()}
                    >
                        {selectedCurrency}
                    </div>
                );
            } else {
                return (
                    <div
                        key={cellProps.column.id}
                        style={{ verticalAlign: 'middle' }}
                        className="align-items-center justify-content-between"
                        // {...cellProps.getCellProps()}
                    >
                        {}
                    </div>
                );
        }
        }
  
        else if (fields.tableRenderType == 'hyperlink' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
            return <div className='ms-3'>
                <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(cellProps.row.original[fields.key])} />
            </div>
        }
        else if (fields.type === 'file' && cellProps.row.original[fields.key] && cellProps.row.original[fields.key] !== "NA") {
            return <div className='ms-3'>
                <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" onClick={() => onClickFileDownload(cellProps.row.original[fields.key], `${fields.key}_${cellProps.row.original.id}`)} />
            </div>
        }
        else if (fields.isMulti || fields.type == 'selectboxes') {
            let value = cellProps.row.original?.[fields.key]
            let arr = value && Array.isArray(value) && value.length && value.map((item: any) => {
                if (item?.value) return item.value
                else return item
            })
            return <div>
                {arr?.length && arr.map((item: any) => {
                    let label = handleSelectValue(item, fields.values);
                    return <div className='ms-0'>
                        <span className="badge bg-success">{label}</span>
                    </div>
                })}
            </div>
        }
        else if (fields.tableRenderType == 'tag') {
            let value = cellProps.row.original?.[fields.key]
            let intakearray = value?.split(",")
            return <div>
                {intakearray?.length && intakearray.map((item: any) => {
                    return <div className='ms-0'>
                        <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                    </div>
                })}
            </div>
        }
        else if (fields?.key && fields?.optionLabel) {
            rendervalue = cellProps?.row?.original?.[fields.key]?.[fields.optionLabel] ? cellProps?.row?.original?.[fields.key]?.[fields.optionLabel] : 'NA'
        }
        else if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
            rendervalue = handleSelectValue(cellProps.row.original[fields.key], fields.values);
        }
        else if (fields.type === "texteditor") {
            return <span className="text-ellipsis"><div className='text-truncate-two-lines' style={{ maxWidth: 200, overflow: 'hidden', maxHeight: 100 }} dangerouslySetInnerHTML={{ __html: cellProps?.row?.original?.[fields.key] }} /></span>
        }
        else if (fields.type === "datetime") {
            return <span className="text-ellipsis">{cellProps.row.original[fields.key] ? moment(cellProps.row.original[fields.key]).format('lll') :
                ''}</span>
        }
        else if (fields.type === "textarea") {
            return <span className="text-ellipsis"><span className='text-truncate-two-lines' style={{ maxWidth: 200, overflow: 'hidden', maxHeight: 100 }}>{cellProps?.row?.original?.[fields.key]}</span></span>
        }
        return <div className="d-flex align-items-center justify-content-between">                                      {
            fields.isPrimary == true ?
                <Row style={{ width: 300 }} className='justfy-content-start align-items-center'>
                    <Col md={12} lg={12} className={'hstack justify-content-between'}>
                        <Link to={`${url}/${cellProps.row.original.id}`} className="text-decoration-none text-ellipsis">
                            <div className='hstack gap-1 '>
                                <UserAvatar img={record && record.img} firstName={cellProps.row.original[fields.key] || cellProps.row.original['first_name']} />
                                {isMergeRender ? rendervalue : cellProps.row.original[fields.key]}
                            </div>
                        </Link>
                        <div className='hstack btn-preview'>
                            <Button
                                size='sm'
                                onClick={() => { setPreview(true); handleLeadClick(cellProps.row.original) }}
                                color="primary"
                                title='Preview'
                                className="btn-primary ms-1 justfy-self-end hoverbutton">
                                <i className="ri-layout-right-2-fill label-icon align-middle fs-12"></i>
                            </Button>
                            {
                                extraNameBtn && communicate && (communicate.toLowerCase() == "all" || (communicate.toLowerCase() == "they_own" && they_own)) ?
                                    <Button
                                        onClick={() => { handleCallInitiate(cellProps.row.original); }}
                                        title='Call'
                                        size='sm'
                                        className="hoverbutton ms-1 justfy-self-end btn btn-sm btn-success remove-list">
                                        <i className="ri-phone-fill label-icon align-middle fs-12"></i>
                                    </Button> : null
                            }
                            {extraNameBtn ?
                                <Button
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        setIsNotesOpen(true); handleLeadClick(cellProps.row.original)
                                    }}
                                    title='Note'
                                    size='sm'
                                    className="btn btn-sm hoverbutton ms-1 justfy-self-end btn-info remove-list">
                                    <i className="ri-sticky-note-fill label-icon align-middle fs-12"></i>
                                </Button>
                                : null}
                        </div>
                    </Col>
                </Row>
                :
                <div>
                    {rendervalue && typeof rendervalue !== 'object' ? rendervalue : null}
                </div>
        }
        </div>
    }
    // Generate view columns
    const viewsColumns = () => {
        let columns: any[] = [];
        fields && fields.length && fields.map((item: any) => {
            const { extras } = item;
            if (metaData && metaData.length > 0) {
                metaData.map((fieldName: any) => {
                    if (fieldName.id == item.id) {
                        let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : fieldName?.valuesJson ? fieldName?.valuesJson : {}
                        let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                        let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                        let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                        if (isFieldviewaccess) {
                            let newColumn = {
                                Header: _.startCase(fields.label),
                                accessor: fields.key,
                                id: fields.key,
                                fieldid: fieldName.id,
                                filterable: true,
                                isSortable: false,
                                disableFilters: true,
                                valueJson: fields,
                                meta: fieldName,
                                show: extras.show,
                                isEditAccess: isFieldEditAccess,
                                isPrimary: fields.isPrimary,
                                Cell: (cellProps: any) => {
                                    return renderCell(cellProps, fields);
                                }
                            }
                            columns.push(newColumn)
                        }
                    }
                });
            }
        })
        if (metaData && metaData.length > 0 && fields && fields.length) {
            const result = metaData.filter((item: any) => !fields.some((field: any) => (item.id === field.id)))
            if (result && result.length > 0) {
                result.map((fieldName: any) => {
                    let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
                    let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                    let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                    let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                    if (isFieldviewaccess) {
                        let newColumn = {
                            Header: _.startCase(fields.label),
                            accessor: fields.key,
                            id: fields.key,
                            fieldid: fieldName.id,
                            filterable: true,
                            isSortable: false,
                            disableFilters: true,
                            valueJson: fields,
                            meta: fieldName,
                            show: fields.tablecolumn,
                            isEditAccess: isFieldEditAccess,
                            isPrimary: fields.isPrimary,
                            Cell: (cellProps: any) => {
                                renderCell(cellProps, fields);
                            }
                        }
                        columns.push(newColumn)
                    }
                });
            }
        }
        return columns;
    }

    // Generate default view columns
    const defaultViewColumn = () => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : fieldName?.valuesJson ? fieldName?.valuesJson : {}
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
                let isFieldEditAccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson, fields) : true;
                const isCurrencyColumn = fields?.key == "currency";
                if (isFieldviewaccess) {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        fieldid: fieldName.id,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: isCurrencyColumn || fields?.tablecolumn,
                        isEditAccess: isFieldEditAccess,
                        isPrimary: fields.isPrimary,
                        Cell: (cellProps: any) => {
                            return renderCell(cellProps, fields);
                        }
                    }
                    columns.push(newColumn)
                }
            });
        }
        return columns
    }

    useEffect(() => {
        let columns: any[] = [];
        if (viewId) columns = viewsColumns();
        else columns = defaultViewColumn();
        const nameColumnIndex = columns.findIndex(column => column.isPrimary == true);
        if (nameColumnIndex !== -1) {
            const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
            columns.unshift(nameColumn); // Add it to the beginning
        }
        if (columns && columns.length > 0) {
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            })
        }
        if (metaData && metaData.length > 0) {
            let datafields: any = [];
            metaData.forEach((fieldName: any) => {
                let field = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {};
                if (field.filter && (field.type === 'textfield' || field.type === 'textarea')) {
                    datafields.push({ label: field.label, value: field.key })
                }
            })
            setFieldOptions(datafields)
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
        setLeftFields(columns)
    }, [metaData, userProfile, fields, communicate, viewId, selectedCurrency]);


    // Columns methods
    // Update visible columns    
    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    // Move column
    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

    // Toggle column visibility
    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
        setLeftFields((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    // Apply column changes
    const onApplyClick = () => {
        setColumns(tempcolumns)
        setLeftFields(tempcolumns)
        if (viewId) {
            let fields: any = []
            tempcolumns && tempcolumns.length &&
                tempcolumns.map((field: any) => {
                    let item = field?.meta && isJson(field?.meta) ? JSON.parse(field?.meta) : {}
                    if (item.isPrimary) fields.push({ id: field.fieldid, extras: { key: item.key, show: true } })
                    else fields.push({ id: field.fieldid, extras: { key: item.key, show: field.show } })
                })
            setFields(fields)
        }
        setEditOpen(false)
    }

    // Deselect all columns
    const deSelectColumnall = () => {
        let tempcolumnsnew: any = []
        tempcolumnsnew = columns.map((column: any, index: number) => {
            if(column.accessor === "createdAt") return {
                    Header: "Created At",
                    accessor: "createdAt",
                    id: "createdAt",
                    filterable: true,
                    isSortable: false,
                    disableFilters: true,
                    show: false,
                    Cell: (cellProps: any) => {
                        return <div className="d-flex align-items-center justify-content-between">
                            {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                                ''}
                        </div>
                    }
            }
            else if (column.isPrimary) return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return renderCell(cellProps, column.valueJson);
                }
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => {
                    return renderCell(cellProps, column.valueJson);
                }
            }
        })
        setColumns(tempcolumnsnew)
        setTempColumns(tempcolumnsnew)
        setLeftFields(tempcolumnsnew)
        setEditOpen(false)
    }

    // Change column index
    const onChangeIndex = (column: any, index: any) => {
        if (index !== -1) {
            let newTempcolumns: any = [...tempcolumns]
            // Remove the column from its current position
            const removedColumn = newTempcolumns.splice(newTempcolumns.indexOf(column), 1)[0];
            // Insert the column at the desired index
            newTempcolumns.splice(index, 0, removedColumn);
            setTempColumns(newTempcolumns);
        }
    }


    // Table Data methods 
    // Decide updating list
    const execute = () => {
        let a = isEqual(list, studentsJson)
        return !a
    }

    // Update list data
    useEffect(() => {
        const parsedData: any = list && list.length ? list.map((item: any) => {
            let valuesJson = item.valuesJson;
            if(model === 'courses' || model === 'institutes') {
                if(userProfile?.tenant?.id && valuesJson?.[userProfile?.tenant?.id]) {
                    let tenantspecific = valuesJson?.[userProfile?.tenant?.id]
                    valuesJson = { ...valuesJson, ...item, ...tenantspecific}
                }
            }
            let values = { ...item, ...valuesJson }
            return values
        }) : []
        let data = parsedData && parsedData.length ? parsedData : []
        setList(data)
        setstudentsJson(list)
    }, [execute()])


    // Handle sort changes
    const handleSort = (columnName: any) => {
        setPagination({ ...pagination, page: 0 })
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
        }
    };


    //Save view 
    const saveview = () => {
        const filtersData = {
            filters: [{
                ...filter,
                search: null
            }],
            sorts: [
                {
                    "field": sortBy,
                    "order": sortOrder
                }
            ]
        }

        // Add search filter
        if (search) {
            filtersData.filters[0].search = {
                term: search,
                field: selectedSearchField?.value,
                valuesLabel: selectedSearchField
            }
        }
        let data = {
            "fields": JSON.stringify(fields),
            "filters": JSON.stringify(filtersData)
        }
        const handleSuccess = () => {
        }
        const handleFailure = () => {

        }
        dispatch(viewUpdateRequest(viewId, data, handleSuccess, handleFailure))
    }

    // Update fields and filters as per view
    useEffect(() => {
        if (view && viewId && view.id == viewId) {
            let newFields: any = view && view.fields && isJson(view.fields) ? JSON.parse(view.fields) : view?.fields
            setFields(newFields)
            let filtersObj: any = view && view.filters && isJson(view.filters) ? JSON.parse(view.filters) : view.filters
            if (filtersObj?.filters?.length || filtersObj?.sorts?.length) {
                const { filters, sorts } = filtersObj;
                if (filters?.length) {
                    if (filters[0]?.search) {
                        setSearch(filters[0].search?.term || '')
                    }
                    if (filters[0]?.search) {
                        setSelectedSearchField(filters[0].search?.valuesLabel || '')
                    }
                    const filter = {
                        quick: [],
                        advance: []
                    }
                    if (filters[0]?.quick) {
                        filter.quick = filters[0]?.quick
                    }
                    if (filters[0]?.advance) {
                        filter.advance = filters[0]?.advance
                    }
                    setFilter(filter)
                }
                if (sorts?.length) {
                    setSortBy(sorts[0].field)
                    setSortOrder(sorts[0].order)
                }
            }
            else {
                setFilter(null)
            }
        }
        else setFilter(null)
    }, [view, viewId])


    const handleLogCall = (record: any, provider: string, callBack?: any) => {
        const data = record?.student ? {
            applicationId: record.id,
            studentId: record.student.id,
            provider
        } : {
            studentId: record.id,
            provider
        };

        const handleSuccess = (response: any): void => {
            dispatch(callListGetRequest(record.id));
            callBack?.(response);
        };

        const handleError = (): void => {
            // Handle error if necessary
            // toast(`Error occurred`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        };

        dispatch(callCreateRequest(data, handleSuccess, handleError));
    };


    const onSendPushNotification = (record: any) => {
        let expoToken = userProfile?.valuesJson && isJson(userProfile?.valuesJson) ? JSON.parse(userProfile?.valuesJson) : null
        if (expoToken?.expo_token) {
            const data = {
                to: expoToken?.expo_token,
                "sound": "default",
                "title": "Call",
                "body": "This is a notification to call",
                "data": {
                    "call_to": record?.mobile
                }
            };
            const handleSuccess = () => {
                toast(`Call notification sent to Mobile`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                setIsCallOpen(false)
                setIsCallLoading(false);
            }
            const handleError = () => {
                toast(`Error initiating call on Mobile.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                setIsCallLoading(false);
            }
            dispatch(pushNotification(data, handleSuccess, handleError));
        }
    }

    const onCallClicked = (record: any, method: string) => {
        setIsCallLoading(true);
        if (method === "1") {
            let expoToken = userProfile?.valuesJson && isJson(userProfile?.valuesJson) ? JSON.parse(userProfile?.valuesJson) : null
            if (expoToken?.expo_token) {
                onSendPushNotification(record);
            } else {
                toast(`Mobile app not installed.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                setIsCallOpen(false)
                setIsCallLoading(false);
            }
        } else {
            initializeCCP();
            handleLogCall(record, "AWS");
            setIsCallOpen(false)
            setIsCallLoading(false);
        }
    };

    const onChangeStatus = (value: any, key: any, data: any, id: any) => {
        let status_key = model == "students" ? "student_status" : "application_status"
        if (value?.fields?.length) {
            const formFields: any = []
            metaData.forEach((fieldJson: any) => {
                const field = fieldJson.valuesJson && isJson(fieldJson.valuesJson) ? JSON.parse(fieldJson.valuesJson) : {}
                const fieldExists = value?.fields.find((vField: any) => vField.value === field.key)
                if (fieldExists) {
                    if (field) {
                        if (!field.validate) {
                            field.validate = {}; // Initialize validate object if it doesn't exist
                        }
                        field.validate.required = fieldExists?.mandatory ?? false; // Set required based on mandatory, defaulting to false if undefined
                        formFields.push(field); // Push the updated field into formFields
                    }
                }
            })
            setStatusForm({ fields: formFields, id, status: value })
            setRecord(data)
        } else {
            setUpdateRow({ ...data, [status_key]: value.value });
            const handleSuccess = (body: any) => {
                setTriggerApi((triggerApi: boolean) => !triggerApi)
            }
            dispatch(updateAction(id, { [status_key]: value.value }, handleSuccess, () => { }))
        }
    };

    const onUpdate = (formValues: any, form?: any) => {
        let status_key = model == "students" ? "student_status" : "application_status"
        const { id, status, existingData } = form;
        setUpdateRow({ ...existingData, [status_key]: status.value });
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        const data = { ...formValues, ...{ [status_key]: status.value } }
        dispatch(updateAction(id, data, handleSuccess, () => { }))
        setStatusForm(null)
    }
    function tog_tooltip_call() {
        setmodal_tooltip_call(!modal_tooltip_call);
    }

    document.title = `DTS | Zilter - ${_.startCase(model)}`;
    return (
        <React.Fragment>
                        <Modal
                isOpen={modal_tooltip_call}
                toggle={() => {
                    tog_tooltip_call();
                }}
            >
                <ModalHeader className="d-flex justify-content-end">
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_tooltip_call(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    >
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <Label className="text-center fs-16">
                        You need to add contact number of student in Students Profile to call.
                    </Label>

                </ModalBody>
            </Modal>
              {notesModals && <NotesModal
                    props={props}
                    notesModals={notesModals}
                    setIsNotesOpen={setIsNotesOpen}
                    record={record}
                    model={model}
                    studentId={studentId}
                    applicationId={applicationId}
                    userProfile={userProfile}
                />}
            {isCallOpen && <CallSelectionModel
                show={isCallOpen}
                onCloseClick={() => setIsCallOpen(false)}
                props={props}
                record={record?.valuesJson ? {...record?.valuesJson, ...record} : record}
                onCallClicked={onCallClicked}
                isCallLoading={isCallLoading}
                
            />}
            {statusForm && <StatusFormModel form={statusForm} onUpdate={onUpdate} onToggle={() => setStatusForm(null)} details={record} />}

            {isCreateModal && <AddEntryModal
                show={isCreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={metaData}
                setTriggerApi={setTriggerApi}
                props={props}
                createAction={createAction}
                parent={parent}
                parentId={parentId}
            />}
            {previewModal && <PreviewModal
                show={previewModal}
                onCloseClick={() => setPreview(false)}
                record={record}
                open={open}
                props={props}
                reducerState={reducerState}
                fieldsMetaData={metaData}
                translater={translater}
                previewAccordianItems={previewAccordianItems}
                model={model}
                studentId={studentId}
                applicationId={applicationId}
                userProfile={userProfile}   />}
            {editModal && <EditModal
                show={editModal}
                onCloseClick={() => setEditOpen(false)}
                onApplyClick={onApplyClick}
                onchange={onchange}
                columns={columns}
                visibleColumns={visibleColumns}
                toggleColumn={toggleColumn}
                onClickmove={onClickmove}
                deSelectColumnall={deSelectColumnall}
                onChangeIndex={onChangeIndex}
                tempcolumns={tempcolumns}
                setTempColumns={setTempColumns}
                leftFields={leftFields}
                setLeftFields={setLeftFields}
            />}

            {arrangeopen && <ArrangeFields
                show={arrangeopen}
                onCloseClick={() => setArrangeopen(false)}
                dataFields={metaData}
                props={props}
            />}

            {isImportCSV && <ImportDataModal
                show={isImportCSV}
                onCloseClick={() => setIsImportCSV(false)}
                props={props}
            />}
            {isDeleteOpen && <DeleteModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                props={props}
                record={record}
                onDelete={onDelete}
            />}

            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="vstack gap-2">
                            <Row className='hstack gap-3'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <InputGroup className='border rounded'>
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-light">
                                                        {selectedSearchField?.label} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setSelectedSearchField({ value: 'all', label: 'All' })}>All</DropdownItem>
                                                        <DropdownItem divider />
                                                        {fieldOptions?.map((option: any) => <DropdownItem onClick={() => setSelectedSearchField(option)}>{option.label}</DropdownItem>)}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                            <Input className='border-0' placeholder="Search..." style={{ padding: '1.2rem 0.9rem' }} onChange={onSearchHandle} value={search}></Input>
                                        </InputGroup>
                                        {search === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => setSearch("")}></i>}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='hstack gap-2 justify-content-end gap-2'>
                                        <div className='hstack gap-2'>
                                            {viewId ? <Button
                                                onClick={() => saveview()}
                                                color="primary"
                                                className="btn-label">
                                                <i className="ri-layout-3-line label-icon align-middle fs-16 me-2"></i>
                                                {props.t("student.save_view")}
                                            </Button> : null}
                                            {createBtn && createBtn()}
                                            <Button
                                                onClick={() => setEditOpen(true)}
                                                color="primary"
                                                className="btn-label btn-soft-primary">
                                                <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("student.edit_columns")}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className=''>
                                {metaData && metaData.length ?
                                    <QuickFilter
                                        dataFields={metaData}
                                        columns={columns}
                                        translation={props}
                                        onFilter={onFilter}
                                        clearFilterParent={clearFilter}
                                        filter={filter}
                                        parentField={parentField}
                                        parentId={parentId}
                                    /> : ""}
                            </Row>
                            <div>
                                {shortListBtn && shortListBtn()}
                                {applyBtn && applyBtn()}
                            </div>
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={ListData || []}
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    isPageSizeChange={true}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    customPageSize={pagination.size}
                                    onChangePageSize={onChangePageSize}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    pagesList={pagesList}
                                    props={props}
                                    access={access}
                                    viewsloading={viewsloading}
                                    dataLoading={dataLoading}
                                    metaLoading={metaLoading}
                                    model={model}
                                    parent={parent}
                                    onChange={onChangeStatus}
                                    updateRow={updateRow}
                                    url={url}
                                    checkbox={checkbox}
                                    updatedCurrency={updatedCurrency}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default withTranslation()(Listing);


