import { Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
import Loader from '../../../FormBuilder/FormBuilder'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Select from "react-select";
import GoogleMeet from "../../../../assets/images/icons8-google-meet-24.png"
// import GoogleMeet from "/Users/omcomputers/Zilter/zilter-dts-develop/zilter-dts-app/src/assets/images/icons8-google-meet-48.png"
import { eventTypeCreateRequest, eventTypeListGetRequest } from "store/calendarSync/action";

const SORTBYNAME = [
    {
        options: [
            { label: "15 min", value: "00:15:00" },
            { label: "30 min", value: "00:30:00" },
            { label: "45 min", value: "00:45:00" },
            { label: "60 min", value: "00:60:00" },
            { label: "Custom", value: "custom" }
        ],
    },
];

const SORTBYDURATION = [
    {
        options: [
            { label: "min", value: "min" },
            { label: "hrs", value: "hrs" }
        ],
    },
];

const CreateEventType = ({ show, onCloseClick, setTriggerApi, emailData }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metaLoading = useSelector((state: ApplicationState) => state.calendarSync.metaLoading);
    const dataFields = useSelector((state: ApplicationState) => state.calendarSync.metaDataFields);
    const [sortBy, setsortBy] = useState<any>(null);
    const [disabled, setDisabled] = useState<any>(false)
    const [eventTypeName, setEventTypeName] = useState("");
    const [eventDuration, setEventDuration] = useState("");
    const [eventLocation, setEventLocation] = useState("Google Meet");
    const [eventType, setEventType] = useState("one_to_one")
    const [sortByDuration, setsortByDuration] = useState<{ label: string; value: string } | null>(
        SORTBYDURATION[0].options[0] // Default: "min"
    );
    const [customDuration, setCustomDuration] = useState("")
    const [isCustomDuration, setIsCustomDuration] = useState<boolean>(false)

    const formatDuration = (duration: any) => {
        if (duration.value !== "custom") {
            setEventDuration(duration.value)
            setIsCustomDuration(false)
        } else {
            setIsCustomDuration(true)
        }
    };

    const handleCustomDuration = (sortBy: any) => {
        const duration = typeof customDuration === "string" ? customDuration : "";
    
        const num = parseInt(duration, 10);
        if (isNaN(num) || num <= 0) return "00:00:00"; // Default if invalid
    
        if (sortBy?.value === "hrs") {
            setEventDuration(`${String(num).padStart(2, "0")}:00:00`)
        } else {
            const hours = Math.floor(num / 60);
            const minutes = num % 60;
            setEventDuration(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:00`)
        }

    }

    const OnAddEntry = () => {
        setDisabled(true)
        let data: any = {
            "duration": eventDuration,
            "location": eventLocation,
            "type": eventType,
            "event_type": eventTypeName
        }
        const handleSuccess = (body: any) => {
            dispatch(eventTypeListGetRequest())
            onCloseClick()
            setDisabled(false)
            setTriggerApi && setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        const handleFailure = (body: any) => {
            setDisabled(false)
        }
        dispatch(eventTypeCreateRequest(data, handleSuccess, handleFailure))
    }

    return (
        <>
            <Modal
                direction="end"
                isOpen={show}
                id="offcanvasExample"
                toggle={onCloseClick}
                backdrop={false}
            >
                <ModalHeader className="p-3 Modal-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onCloseClick(!show) }}>
                    <span className="m-0 me-2 text-white">
                        New Event Type
                    </span>
                </ModalHeader>
                <ModalBody>
                    <Card className="border-0" style={{boxShadow:'none'}}>
                        <CardBody className="border-0">
                            <SimpleBar>
                                <div className="live-preview vstack gap-2">
                                    <Col xxl={12} md={12}>
                                        <div>
                                            <Label htmlFor="exampleInputrounded" className="form-label">Event name <span className="text-danger">*</span></Label>
                                            <Input type="text" className="form-control" id="exampleInputrounded" placeholder="Enter event type" onChange={(e: any) => setEventTypeName(e.target.value)} />
                                        </div>
                                    </Col>
                                    <Col xxl={6} md={6}>
                                        <div>
                                            <Label htmlFor="exampleInputrounded" className="form-label">Duration <span className="text-danger">*</span></Label>
                                            <Select
                                                value={sortBy}
                                                onChange={(sortBy: any) => {
                                                    setsortBy(sortBy)
                                                    formatDuration(sortBy)
                                                }}
                                                options={SORTBYNAME}
                                                id="choices-single-default"
                                                className="js-example-basic-single mb-0"
                                                name="state"
                                                menuPlacement="auto"
                                                menuPosition="fixed"
                                            />
                                        </div>
                                        {isCustomDuration ? (
                                            <>
                                                <div className="mt-3 d-flex gap-2">
                                                    <Input
                                                        type="text"
                                                        className="w-50 form-control flex-grow-1"
                                                        id="exampleInputrounded"
                                                        placeholder=""
                                                        onChange={(e: any) => {
                                                            setCustomDuration(e.target.value)
                                                            console.log("custome",customDuration)
                                                        }}
                                                        maxLength={2} // Restrict input to 2 digits
                                                    />
                                                    <Select
                                                        value={sortByDuration}
                                                        onChange={(sortBy: any)=>{
                                                            setsortByDuration(sortBy)
                                                            handleCustomDuration(sortBy)
                                                        }}
                                                        options={SORTBYDURATION}
                                                        id="duration-unit-select"
                                                        className="flex-grow-1 js-example-basic-single mb-0"
                                                        name="state"
                                                        menuPlacement="auto"
                                                        menuPosition="fixed"
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                    </Col>
                                    <Col xxl={12} md={12} className="mb-2">
                                        <div>
                                            <Label htmlFor="exampleInputrounded" className="form-label">Location <span className="text-danger">*</span></Label>
                                            <div className="d-flex gap-2 align-items-center">
                                            <img src={GoogleMeet} className="rounded-circle" alt="" />
                                            <div>
                                                <Label className="fs-15 fw-bold text-muted mb-0">Google Meet</Label>
                                            </div>
                                            </div>
                                            {/* <div className="d-flex justify-content-between align-content-middle">
                                                <div className="">
                                                    <Button className="text-nowrap text-truncate text-nowrap-ellipsis btn btn-ghost-primary waves-effect waves-light" size="lg" color="primary">
                                                        <i className="ri-live-line"></i>
                                                        <p className="fs-14">Zoom</p>
                                                    </Button>
                                                </div>
                                                <div className="">
                                                    <Button className="text-nowrap text-truncate text-nowrap-ellipsis btn btn-ghost-primary waves-effect waves-light" size="lg" color="primary">
                                                        <i className="ri-phone-line"></i>
                                                        <p className="fs-14">Phone call</p>
                                                    </Button>
                                                </div>
                                                <div className="">
                                                    <Button className="text-nowrap text-truncate text-nowrap-ellipsis btn btn-ghost-primary waves-effect waves-light" size="lg" color="primary">
                                                        <i className="ri-user-location-line"></i>
                                                        <p className="fs-14">In-person</p>
                                                    </Button>
                                                </div>
                                                <div className="">
                                                    <Button className="text-nowrap text-truncate text-nowrap-ellipsis btn btn-ghost-primary waves-effect waves-light" size="lg" color="primary">
                                                        <i className="ri-arrow-down-s-line"></i>
                                                        <p className="fs-14">All options</p>
                                                    </Button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </Col>
                                </div>
                                <div className="d-flex justify-content-center">
                                <Button disabled={!eventType || !eventDuration} color="primary" onClick={OnAddEntry}>Done</Button>
                                </div>
                               
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </>
    )
}
export default CreateEventType;