import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { useState } from "react";
import SimpleBar from "simplebar-react";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";

const AddEntry = ({ props, show, onCloseClick, dataFields, setTriggerApi, createAction, parent, parentId, to }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [disabled, setDisabled] = useState<any>(false)
        const [emailData, setEmailData] = useState<any>({
            to: to
        })  
    const OnAddEntry = (formValues: any) => {
        setDisabled(true)
        let data: any = parent ? {...formValues, [parent]: parentId} : formValues;
        const handleSuccess = (body: any) => {
            onCloseClick()
            setDisabled(false)
            setTriggerApi && setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        const handleFailure = (body: any) => {
            setDisabled(false)
        }
        dispatch(createAction(data, handleSuccess, handleFailure))
    }
    return (
        <Modal
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >

            <ModalHeader className="p-3 Modal-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2 text-white">
                    Create new event
                </span>
            </ModalHeader>
            <ModalBody>
            <Card  className="border-0" style={{boxShadow:'none'}}>
                    <CardBody className="border-0">
                        <SimpleBar style={{maxHeight: 450}}>
                            <div className="live-preview vstack gap-2">
                            <DtsEmailsInputs placeholder={"To"} hideCross={true} disabled={true} defaultValue={[`${emailData.to}`]} setdisabled={false} onChange={(values: any) => setEmailData({ ...emailData, to: values })} />

                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Create Event'} formfor="students"  btndisabled={disabled} />
                            </div>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default AddEntry;

