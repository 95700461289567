import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import {  ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { bankListGetRequest, bankCreateRequest } from "store/banks/action";
import { useParams } from "react-router";
import { useState } from "react";
    

const AddEntry = ({ props, show, onCloseClick, dataFields, parentField }: any) => {   
    const {id} = useParams()
    const [disabled, setDisabled] = useState<any>(false)
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const OnAddEntry = (formValues: any) => {
    setDisabled(true)
    let data = {...formValues, companies: id};
    const handleSuccess = (body: any) => {
        onCloseClick()
        const filtersData: any = {
            "filters": [
                {
                    quick: [],
                    advance: [], 
                    search: null,
                    parent:  [{
                        "key": parentField?.key,
                        "keyLabel": parentField?.label,
                        "condition": "IN",
                        "conditionLabel": "is any of",
                        "values": [id],
                        "valuesLabel": [],
                        "property": parentField,
                        "quick": true,
                    }]
                }
            ],
            "sorts": [
                {
                    "field": "createdAt",
                    "order": "desc"
                }
            ]
        }
        dispatch(bankListGetRequest(filtersData));
        setDisabled(false)
    }
    const handleError = () => {

    }
    dispatch(bankCreateRequest(data, handleSuccess ,handleError))
  }

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("bank.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody>
                <Card  className="border-0" style={{boxShadow:'none'}}>
                    <CardBody className="border-0">
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Add Entry'} btndisabled={disabled}/>
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

